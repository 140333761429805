import * as React from "react"
import { graphql } from "gatsby"

import Layout from "./layout2022"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="220812 - Fukushima Peach Trip" />
      <h3 className="font-title-sub text-center">Fukushima Peach Trip</h3>
      <p>After 2 hours driving (me on passenger seat as always), we arrived in Fukushima to get affordable peach, and we got 6 boxes in the end!</p>
      <div className="text-center">
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../../../images/journal/2022/sketch_220812_1.jpg"
          width={400}
          quality={95}
        />
      </div>
      <p>In the middle of way, we dropped by roadside station "Atsukashi no sato", and found house plants were on sale. I picked cordyline, caladium, and lvy for my room. (3 for less than 2000 yen!)</p>
      <div className="text-center">
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../../../images/journal/2022/sketch_220812_2.jpg"
          width={400}
          quality={95}
        />
      </div>
      <p>We ate Sapporo ramen for lunch and the soup was so good. Many peaches, house plants...our car was like after back from stocking.</p>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
